import { useState } from "react"
import styled from "styled-components"

import { Divider, List, ListItemText } from "@material-ui/core"

import {
  OrganizationAvatar,
  RoundProfilePicture,
} from "src/components/Account/Avatars"
import { ErrorBoundary } from "src/components/ErrorBoundary/ErrorBoundary"
import { useAppData } from "src/context/useAppData"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useFetchOrganizations } from "src/data/organizations/queries/organizationQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useLogout } from "src/hooks/useLogout"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TPartialLocation } from "src/router/routeTypes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { recommendedGray } from "src/ui/colors"
import CardIcon from "src/ui/icons/card.svg"
import Checkmark from "src/ui/icons/checkmark-base.svg"
import CreateOrgIcon from "src/ui/icons/create-org.svg"
import Chevron from "src/ui/icons/expand-down.svg"
import JoinOrgIcon from "src/ui/icons/join-org.svg"
import OrganizationIcon from "src/ui/icons/organization.svg"
import ProfileIcon from "src/ui/icons/profile.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import {
  ellipsis,
  Fullname,
  Menu,
  ProfileInfo,
  ProfileInfoCenter,
  ProfileInfoCenterTitle,
  ProfileListItem,
  StyledListItem,
  StyledListItemIcon,
} from "./sharedStyles"

const SIXTY_SECONDS = 60 * 1000
const MAX_ORGS = 5

interface TAccountPages {
  url: TPartialLocation
  label: string
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  hidden?: boolean
}

export function ProfileButton({
  showLabel = true,
  clickCb = () => {},
}: {
  showLabel?: boolean
  clickCb?: () => void
}) {
  const { setActiveOrgId } = useAppData()
  const { org, orgAccessLogic } = useOrganization()

  const isOrgOwner = orgAccessLogic.hasOwnerAccess

  const fetchOrganizations = useFetchOrganizations({
    params: { limit: MAX_ORGS },
    options: { staleTime: SIXTY_SECONDS },
  })

  const hasMaxOrgs =
    (fetchOrganizations.data?.paging.total_count || 0) > MAX_ORGS

  const orgNavList = hasMaxOrgs
    ? [org]
    : (fetchOrganizations.data?.organizations ?? [])

  const { t, langKeys } = useTranslate()

  const [anchorEl, setAnchorEl] = useState(null)

  const user = useGetUser()
  const { navigate } = useRouter()

  const { logout } = useLogout()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clickMenuItem = (func: () => void) => () => {
    handleClose()
    func()
  }

  const accountPages: Readonly<TAccountPages[]> = [
    {
      url: Routes.AccountGeneral.location(),
      label: t(langKeys.account),
      icon: ProfileIcon,
    },
    {
      url: Routes.AccountBilling.location(),
      label: t(langKeys.settings_billing),
      icon: CardIcon,
      hidden: !isOrgOwner,
    },
  ] as const

  return (
    <ErrorBoundary mini>
      <StyledMButton
        aria-controls="profile-menu"
        aria-haspopup="true"
        variant="minimal"
        onClick={handleClick}
        fullWidth={showLabel}
      >
        <ProfileInfo>
          <OrganizationAvatar orgName={org.name} />
          {showLabel && (
            <ProfileInfoCenter>
              <ProfileInfoCenterTitle>
                <SubtitleSEllipsis>{org.name}</SubtitleSEllipsis>
                <Chevron fill={recommendedGray} width={10} />
              </ProfileInfoCenterTitle>

              <Fullname>{user.fullname}</Fullname>
            </ProfileInfoCenter>
          )}
        </ProfileInfo>
      </StyledMButton>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <List component="nav">
          <ProfileListItem>
            <StyledListItemIcon>
              <RoundProfilePicture />
            </StyledListItemIcon>
            <ProfileInfoCenter>
              <MText variant="body">{user.fullname}</MText>
              <MText variant="bodyS" color="secondary">
                {user.email}
              </MText>
            </ProfileInfoCenter>
          </ProfileListItem>

          <Divider />

          {orgNavList.map((o) => (
            <StyledListItem
              key={o.id}
              button
              onClick={clickMenuItem(() => {
                setActiveOrgId(o.id)
                navigate(Routes.Dashboard.location(), { orgIdOverride: o.id })
              })}
            >
              <StyledListItemIcon>
                <OrganizationAvatar orgName={o.name} size="small" />
              </StyledListItemIcon>
              <ListItemText primary={o.name} />
              {o.id === org.id && <StyledCheckmark />}
            </StyledListItem>
          ))}

          {hasMaxOrgs && (
            <StyledListItem
              button
              onClick={clickMenuItem(() => {
                navigate(Routes.Organizations.location())
              })}
            >
              <StyledListItemIcon>
                <OrganizationIcon width="24" height="24" />
              </StyledListItemIcon>
              <ListItemText
                primary={t(langKeys.organizations_view_all_organizations)}
              />
            </StyledListItem>
          )}

          <StyledListItem
            button
            onClick={clickMenuItem(() => {
              navigate(Routes.OrgCreate.location())
            })}
          >
            <StyledListItemIcon>
              <CreateOrgIcon width="24" height="24" />
            </StyledListItemIcon>
            <ListItemText primary={t(langKeys.organization_create)} />
          </StyledListItem>

          <StyledListItem
            button
            onClick={clickMenuItem(() => {
              navigate(Routes.OnboardingJoinOrganization.location())
            })}
          >
            <StyledListItemIcon>
              <JoinOrgIcon width="24" height="24" />
            </StyledListItemIcon>
            <ListItemText primary={t(langKeys.join_organization)} />
          </StyledListItem>
          <Divider />

          {accountPages
            .filter((acc) => !acc.hidden)
            .map((page) => (
              <StyledListItem
                key={page.url.pathname}
                button
                onClick={clickMenuItem(() => {
                  clickCb()
                  navigate(page.url)
                })}
              >
                <StyledListItemIcon>
                  {page.icon && <page.icon width="24" height="24" />}
                </StyledListItemIcon>
                <ListItemText primary={page.label} />
              </StyledListItem>
            ))}

          <Divider />

          <StyledListItem
            button
            onClick={clickMenuItem(() =>
              logout({ reason: "User clicked logout in side nav" })
            )}
          >
            <ListItemText
              primary={t(langKeys.sign_out)}
              style={{ marginLeft: "17px" }}
            />
          </StyledListItem>
        </List>
      </Menu>
    </ErrorBoundary>
  )
}

const StyledCheckmark = styled(Checkmark)`
  margin-left: ${spacing.M};
`

const SubtitleSEllipsis = styled(MText).attrs(() => ({
  color: "secondary",
  variant: "subtitleS",
}))`
  ${ellipsis};
`

const StyledMButton = styled(MButton)`
  text-decoration: none;
`
